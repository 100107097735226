import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function DisabledError({ translate, sport }) {
    return (
        <div className={cn(sport, 'error')}>
            <p className="error__text">{translate('error.matchDisabled')}</p>
        </div>
    );
}

DisabledError.propTypes = {
    classNames: PropTypes.shape({
        bg: PropTypes.string,
        font: PropTypes.string,
        btnBgColor: PropTypes.string,
        btnColor: PropTypes.string,
    }).isRequired,
    translate: PropTypes.func.isRequired,
};
