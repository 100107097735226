import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const getBgClass = (home, away, isHome) => {
    const equalsClass = 'statistics-logger__progress-bar--equals';
    const leadingClass = 'statistics-logger__progress-bar--leading';
    const laggingBehindClass = 'statistics-logger__progress-bar--lagging-behind';

    if (home === away) {
        return equalsClass;
    }

    if (home > away) {
        return isHome ? leadingClass : laggingBehindClass;
    }

    return isHome ? laggingBehindClass : leadingClass;
};

export const ProgressBar = ({ width, sidesStat = {}, isHome = false }) => {
    const bgClass = getBgClass(sidesStat?.homeStat, sidesStat?.awayStat, isHome);
    const classes = cx(`progress-bar ${bgClass}`);

    return <div className={classes} style={{ width }} />;
};

ProgressBar.propTypes = {
    width: PropTypes.string.isRequired,
    isHome: PropTypes.bool,
    sidesStat: PropTypes.shape({
        homeStat: PropTypes.number,
        awayStat: PropTypes.number,
    }),
};
