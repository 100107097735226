import { initI18n } from 'i18n';
import { assign } from 'xstate';

export const loadingTranslations = {
    id: 'loading-translations-invoke',
    src: 'sportTranslations',
    onDone: {
        target: 'idle',
        actions: [
            assign({
                initI18n: ({ event }) => {
                    initI18n(event.output);
                },
            }),
        ],
    },
    onError: {
        target: 'idle',
        actions: [
            assign({
                initI18n: () => {
                    initI18n();
                },
            }),
        ],
    },
};
