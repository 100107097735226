import { assign } from 'xstate';

import { machineUtils } from 'features/common/utils';

export const newEvents = {
    invoke: {
        id: 'newEvents-invoke',
        src: 'newEvents',
        onDone: {
            target: 'delay',
            actions: assign({
                store: machineUtils.storeAction,
                lastEventId: machineUtils.lastEventIdAction,
            }),
        },
        onError: [
            {
                target: 'disabledDelay',
                guard: 'isWidgetDisabled',
                actions: assign({ error: ({ event }) => event.error }),
            },
            {
                target: 'delay',
                actions: assign({ error: ({ event }) => event.error }),
            },
        ],
    },
};
