/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const BetAndreasVariant = (props) => (
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.619 5.618C2.836 7.4 1.939 9.66 1.485 11.455l8.06 8.058c1.794-.452 4.054-1.35 5.837-3.134 1.783-1.784 2.68-4.042 3.134-5.837l-8.06-8.057c-1.795.452-4.054 1.35-5.837 3.133Zm7.175 2.69.898-.896a.634.634 0 0 1 .896.895l-.896.898.449.449a.634.634 0 1 1-.897.897l-.448-.449-.899.897.448.449a.634.634 0 0 1-.896.896L10 11.896l-.896.897.448.449a.634.634 0 0 1-.897.896l-.449-.447-.898.896a.634.634 0 0 1-.895-.896l.895-.898-.448-.449a.634.634 0 1 1 .897-.896l.448.448.899-.897-.449-.448a.634.634 0 0 1 .897-.897l.448.448.897-.897-.448-.448a.634.634 0 0 1 .896-.897l.45.447Zm7.069.427c.293-2.19.16-4.832-.804-5.794-.964-.962-3.603-1.097-5.794-.803-.101.013-.208.029-.318.046l6.87 6.87.046-.319Zm-17.725 4.53c-.293 2.19-.16 4.832.804 5.794.672.673 2.166.94 3.753.94a15.544 15.544 0 0 0 2.358-.183l-6.87-6.87-.045.319Z"
            fill="#9B9BB3"
        />
    </svg>
);
const ViviVariant = (props) => (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_61287_54893)">
            <path
                d="M4 0H2C0.895195 0 0 0.895781 0 2.00059V4.00059C0 4.43859 0.0266016 4.87098 0.0728125 5.2982C1.46422 5.28781 2.77141 4.7434 3.75723 3.75781C4.74223 2.77281 5.28781 1.46562 5.29762 0.0728125C4.87102 0.0266016 4.43859 0 4 0ZM15.9272 10.7032C14.5354 10.713 13.2286 11.2574 12.2428 12.2428C11.2578 13.2278 10.713 14.5346 10.7026 15.9278C11.129 15.9734 11.5606 16 12 16H13.9992C15.1042 16 16 15.1042 16 14V12C16 11.562 15.9734 11.129 15.9272 10.7032Z"
                fill="#C3C6D9"
            />
            <path
                d="M6.6198 0.296875C6.485 3.73309 3.7298 6.48566 0.295387 6.62109C1.30398 11.1419 4.858 14.6979 9.38 15.7057C9.5148 12.2693 12.2708 9.5175 15.7044 9.38211C14.696 4.86129 11.142 1.30527 6.6198 0.296875ZM10.8282 9.8873L9.88539 10.8303L8.94257 9.8873L8 10.8303L7.05738 9.88727L8 8.94465L7.05738 8.00184L6.11457 8.94465L5.17117 8.00184L6.11457 7.05922L5.17117 6.11641L6.11457 5.17301L7.05738 6.11641L8 5.17301L8.94261 6.11641L8 7.0593L8.94261 8.00191L9.88543 7.0593L10.8282 8.00191L9.88543 8.94473L10.8282 9.8873Z"
                fill="#C3C6D9"
            />
        </g>
        <defs>
            <clipPath id="clip0_61287_54893">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const MostBetVariant = (props) => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.188 20c-1.7 0-3-.3-3.5-.8-1.1-1.2-1.5-9.6 3.7-14.8 3.3-3.3 8-4.4 11.3-4.4 1.7 0 3 .3 3.5.8.5.4.8 1.7.8 3.3.1 3.4-1.1 8.2-4.4 11.5-3.3 3.2-8.1 4.4-11.4 4.4Zm-2.1-2.2c.6.1 1.3.2 2.1.2s1.6-.1 2.4-.2l-4.5-4.5c-.4 2.1-.2 3.8 0 4.5Zm6.8-.6c2.2-.6 3.9-1.7 5.3-3 1.3-1.3 2.3-3 3.1-5.3l-1.1-1.1c-.4 1.6-1.2 3.8-2.9 5.5-1.6 1.6-3.8 2.5-5.5 2.9l1.1 1Zm-2.7-2.7c1.7-.3 4-1 5.7-2.7 1.4-1.4 2.3-3.3 2.7-5.7l-3.5-3.5c-2.3.7-4 1.8-5.4 3.1-1.3 1.4-2.4 3.2-3.1 5.3l3.6 3.5Zm11.6-7.9c.3-1.9.3-3.7.1-4.5-.3-.1-1-.2-2.1-.2-.8 0-1.6.1-2.5.2l4.5 4.5Zm-11.5 6c-.3 0-.5-.1-.7-.3l-1.1-1.1c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.5.3-.7.3Zm1.7-1.8c-.3 0-.5-.1-.7-.3l-1.1-1.2c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.2c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3Zm1.8-1.7c-.2 0-.5-.1-.7-.3l-1.1-1.2c-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7.2-.1.4-.2.7-.2.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.5-.3.7-.2.2-.4.3-.7.3Zm1.7-1.8c-.3 0-.5-.1-.7-.3l-1.1-1.2c-.4-.4-.4-1 0-1.4.2-.2.4-.3.7-.3.3 0 .5.1.7.3l1.1 1.1c.2.2.3.4.3.7 0 .3-.1.6-.3.8-.2.2-.4.3-.7.3Z"
            fill="#305D17"
        />
    </svg>
);

export const Ball = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: <MostBetVariant {...props} />,
        [targets.BETANDREAS]: <BetAndreasVariant {...props} />,
        [targets.VIVI]: <ViviVariant {...props} />,
    }));

    return icon;
};
