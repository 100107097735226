import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RootMachineContext } from 'features/common/providers/RootMachineProvider';

export const useAttackStatistic = (subtextKey = 'statistics.puckOwningAbsolute') => {
    const [translate] = useTranslation();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { statistics } = store.translationData;
    const { puckOwning } = statistics;

    // eslint-disable-next-line no-shadow
    const getTotalOwningTime = (team) => puckOwning[team].reduce((acc, curr) => acc + curr.owningTime, 0);
    const homeOwningTime = getTotalOwningTime(1);
    const awayOwningTime = getTotalOwningTime(2);

    const totalTime = homeOwningTime + awayOwningTime;
    const homeStat = Math.round((homeOwningTime / totalTime) * 100);
    const awayStat = 100 - homeStat;

    return {
        homeStat,
        awayStat,
        label: translate(subtextKey),
    };
};
